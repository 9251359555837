<template>
  <div>
    <h1>Privacy Management</h1>
    <div class="fc-container-sm">
      <h2>OneTrust Requests</h2>
      <div class="fc-flex fc-align-center">
        <form class="fc-flex fc-align-center" @submit.prevent="fetchRequest">
          <FCInput
            v-model="requestId"
            type="text"
            title="Request ID"
            name="RequestId" />
          <ButtonAction
            primary
            :disabled="loading"
            @click.native="fetchRequest">
            Search
          </ButtonAction>
        </form>
        <ButtonAction
          class="fc-margin-left-2"
          :disabled="loading"
          @click.native="fetchAllRequests">
          View All
        </ButtonAction>
      </div>

      <p v-if="error" class="fc-text-red fc-bold">
        {{ error }}
      </p>

      <!-- Process Response -->
      <div v-if="processResponse" class="fc-margin-top-4 process-response">
        <h3>Process Results</h3>
        <div class="request-details">
          <h4>Identity Information</h4>
          <table class="details-table">
            <tbody>
              <tr v-for="(value, key) in processResponse.identity" :key="key">
                <th>{{ formatKey(key) }}</th>
                <td>{{ value || 'N/A' }}</td>
              </tr>
            </tbody>
          </table>

          <h4 class="fc-margin-top-4">
            Process Report
          </h4>
          <div class="provider-report">
            <h5>{{ formatProviderName(processResponse.report.provider.name) }}</h5>
            <div class="provider-items">
              <div v-for="(item, index) in processResponse.report.items" :key="index">
                <div :class="{ 'success-message': item.log, 'error-message': item.error }">
                  {{ item.log || item.error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Single Request Result -->
      <div v-if="singleRequest" class="fc-margin-top-4">
        <h3>Request Details</h3>
        <div class="request-details">
          <table class="details-table">
            <tbody>
              <tr>
                <th>Reference</th>
                <td>{{ singleRequest.request.refId }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ singleRequest.request.email }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{ singleRequest.request.status }}</td>
              </tr>
            </tbody>
          </table>

          <div class="fc-flex fc-align-center">
            <ButtonAction
              :disabled="loading"
              primary
              @click.native="processRequest(singleRequest.request.refId)">
              Process Request
            </ButtonAction>
          </div>

          <div v-if="singleRequest.reports" class="reports-section">
            <h4>Identity Information</h4>
            <table class="details-table">
              <tbody>
                <tr v-for="(value, key) in singleRequest.reports.identity" :key="key">
                  <th>{{ formatKey(key) }}</th>
                  <td>{{ value || 'N/A' }}</td>
                </tr>
              </tbody>
            </table>

            <h4 class="fc-margin-top-4">
              Provider Reports
            </h4>
            <div
              v-for="report in singleRequest.reports.reports"
              :key="report.provider.name"
              class="provider-report">
              <h5>{{ formatProviderName(report.provider.name) }}</h5>
              <div v-if="report.items.length" class="provider-items">
                <div v-for="(item, index) in report.items" :key="index">
                  <table v-if="!item.error" class="details-table">
                    <tbody>
                      <tr v-for="(value, key) in flattenObject(item)" :key="key">
                        <th>{{ formatKey(key) }}</th>
                        <td>
                          <span v-if="isImageUrl(value)">
                            <img
                              :src="value"
                              alt="Profile"
                              class="thumbnail-image">
                          </span>
                          <span v-else-if="typeof value === 'object'">
                            {{ JSON.stringify(value, null, 2) }}
                          </span>
                          <span v-else>
                            {{ value }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="error-message">
                    {{ item.error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- All Requests Result -->
      <div v-if="allRequests.length" class="fc-margin-top-4">
        <h3>All Requests</h3>
        <table>
          <thead>
            <tr>
              <th>Reference</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in allRequests" :key="request.id">
              <td>{{ request.refId }}</td>
              <td>{{ request.email }}</td>
              <td>{{ request.status }}</td>
              <td>
                <ButtonAction
                  :disabled="loading"
                  @click.native="selectRequest(request.refId)">
                  Select
                </ButtonAction>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <div class="fc-flex fc-justify-center fc-margin-top-4">
          <ButtonAction
            :disabled="currentPage === 0"
            @click.native="changePage(currentPage - 1)">
            Previous
          </ButtonAction>
          <span class="fc-margin-left-2 fc-margin-right-2">
            Page {{ currentPage + 1 }}
          </span>
          <ButtonAction
            :disabled="allRequests.length < 10"
            @click.native="changePage(currentPage + 1)">
            Next
          </ButtonAction>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { FCInput } from '@fc/angie-ui';
  import axios from 'axios';
  import ButtonAction from '@/shared/components/ButtonAction.vue';

  export default {
    name: 'PrivacyManagement',
    components: {
      FCInput,
      ButtonAction,
    },
    data() {
      return {
        loading: false,
        error: '',
        requestId: '',
        singleRequest: null,
        allRequests: [],
        currentPage: 0,
        processResponse: null,
      };
    },
    methods: {
      reset() {
        this.error = '';
        this.singleRequest = null;
        this.allRequests = [];
        this.processResponse = null;
      },
      async selectRequest(requestId) {
        this.requestId = requestId;
        await this.fetchRequest();
      },

      async fetchRequest() {
        if (!this.requestId) return;
        this.loading = true;

        try {
          const response = await axios.post('/.netlify/functions/privacyManagement', {
            action: 'get',
            requestId: this.requestId,
          });

          // const response = await axios.get(`http://localhost:5002/anonymize/requests/${this.requestId}`);
          this.reset();
          this.singleRequest = response.data;

          if (!this.singleRequest) {
            this.error = 'Request not found.';
          }
        } catch (e) {
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      },

      async fetchAllRequests() {
        this.loading = true;

        try {
          const response = await axios.post('/.netlify/functions/privacyManagement', {
            action: 'list',
            page: this.currentPage,
          });

          // const response = await axios.get(`http://localhost:5002/anonymize/requests/?page=${this.currentPage}`);
          this.reset();
          this.allRequests = response.data;

          console.log(response);

          if (this.allRequests?.length === 0) {
            this.error = 'No requests found.';
          } else {
            this.error = '';
          }
        } catch (e) {
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      },

      async processRequest(requestId) {
        this.loading = true;
        try {
          const response = await axios.post('/.netlify/functions/privacyManagement', {
            action: 'process',
            requestId,
          });
          // const response = await axios.post(`http://localhost:5002/anonymize/requests/${requestId}`, { requestId });

          this.processResponse = response.data;
          // Show error if the response indicates a problem
          if (this.processResponse?.report?.items.some(item => item.error)) {
            this.error = 'Process completed with errors. See details below.';
          } else {
            this.error = '';
          }
        } catch (e) {
          this.error = e.message;
          this.processResponse = null;
        } finally {
          this.loading = false;
        }
      },

      async changePage(newPage) {
        if (newPage < 0) return;
        this.currentPage = newPage;
        await this.fetchAllRequests();
      },

      formatKey(key) {
        return key
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      },

      formatProviderName(name) {
        return name
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      },

      isImageUrl(value) {
        return typeof value === 'string' && (value.startsWith('http') && (value.endsWith('.jpg') || value.endsWith('.png') || value.endsWith('.gif')));
      },

      flattenObject(obj, prefix = '') {
        return Object.keys(obj).reduce((acc, key) => {
          const value = obj[key];
          if (value === null || typeof value !== 'object' || Array.isArray(value) || key === 'identities' || key === 'devices') {
            acc[prefix + key] = value;
          } else {
            Object.assign(acc, this.flattenObject(value, `${prefix}${key}_`));
          }
          return acc;
        }, {});
      },
    },
  };
</script>

<style scoped lang="scss">

table {
  border-collapse: collapse;
}
 th {
  background: #ccc;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #efefef;
}

tr:hover {
  background: #d1d1d1;
}

.request-details {
  max-width: 1200px;
  margin: 0 auto;
}

.reports-section {
  margin-top: 2rem;
}

.provider-report {
  margin: 1.5rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;

  h5 {
    margin-bottom: 1rem;
    color: #666;
  }
}

.provider-items {
  > div {
    margin-bottom: 1rem;
  }
}

.error-message {
  color: #dc3545;
  padding: 0.5rem;
  background-color: #f8d7da;
  border-radius: 4px;
}

.thumbnail-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.details-table {
  width: 100%;
  margin-bottom: 1rem;

  th {
    width: 200px;
    text-align: left;
  }

  td {
    word-break: break-word;
  }
}

.process-response {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.success-message {
  color: #155724;
  padding: 0.5rem;
  background-color: #d4edda;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
</style>
